import { Box, ColorProps } from "@chakra-ui/react";
import React from "react";

export const InternationalContestMark = ({
  children,
  bg = "brand.200",
}: {
  children: React.ReactNode;
  bg?: ColorProps["fill"];
}) => (
  <Box as="mark" display="inline-block" bg={bg}>
    {children}
  </Box>
);
