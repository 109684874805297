import React, { createContext, ReactNode } from "react";

export const InternationalContestUserTimezoneContext = createContext(null);

export const InternationalContestTimezoneProvider = ({
  children,
  userTimezone,
}: {
  children: ReactNode;
  userTimezone: string;
}) => (
  <InternationalContestUserTimezoneContext.Provider value={userTimezone}>
    {children}
  </InternationalContestUserTimezoneContext.Provider>
);
