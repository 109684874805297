import { Box, Container, VStack } from "@chakra-ui/react";
import { gql } from "graphql-request";
import { useTranslation } from "next-i18next";
import { Temporal } from "temporal-polyfill";
import { useMemo } from "react";
import { InternationalContestHero } from "@/modules/international/contest/pages/sections/InternationalContestHero";
import { InternationalContestFeatures } from "@/modules/international/contest/pages/sections/InternationalContestFeatures";
import { InternationalContestAbout } from "@/modules/international/contest/pages/sections/InternationalContestAbout";
import { InternationalContestSteps } from "@/modules/international/contest/pages/sections/InternationalContestSteps";
import { InternationalFooter } from "@/modules/international/common/components/InternationalFooter";
import { InternationalNavbar } from "@/modules/international/common/components/InternationalMenu";
import { InternationalContestFaqs } from "@/modules/international/contest/pages/sections/InternationalContestFaqs";
import { InternationalContestPageQuery } from "@/gql-types";
import { InternationalContestProcess } from "./sections/InternationalContestProcess";
import { toUserTimezone } from "../../common/utils";
import { InternationalContestEndedSteps } from "./sections/InternationalContestEndedSteps";

export interface InternationalContestPageProps {
  event: InternationalContestPageQuery["international"]["international_site"]["event"];
}

export const InternationalContestPage = ({ event }: InternationalContestPageProps) => {
  const { i18n } = useTranslation(["common", "internationalContest"]);
  const nowDateTime = Temporal.Now.plainDateTimeISO();

  const { activeEvents, pastEvents: endedEvents } = useMemo(() => {
    const ongoingEvents = [];
    const finishedEvents = [];
    event.children.reverse().forEach((child) => {
      const finishTimes = child.steps.map((step) =>
        Temporal.PlainDateTime.from(toUserTimezone(step.finish_time).toString()),
      );
      const allFinished = finishTimes.every((time) => Temporal.PlainDateTime.compare(time, nowDateTime) <= 0);
      if (allFinished) finishedEvents.push(child);
      else ongoingEvents.push(child);
    });
    return { activeEvents: ongoingEvents, pastEvents: finishedEvents };
  }, [event.children, nowDateTime]);

  const nearestContest = useMemo(
    () =>
      activeEvents
        .flatMap(({ steps }) => steps)
        .reduce((prev, current) => {
          if (!prev) return current;
          const prevTime = Temporal.PlainDateTime.from(toUserTimezone(prev.finish_time));
          const currentTime = Temporal.PlainDateTime.from(toUserTimezone(current.finish_time));
          const prevIsPast = Temporal.PlainDateTime.compare(prevTime, nowDateTime) <= 0;
          const currentIsPast = Temporal.PlainDateTime.compare(currentTime, nowDateTime) <= 0;
          if (prevIsPast) return current;
          if (currentIsPast) return prev;
          if (Temporal.PlainDateTime.compare(currentTime, prevTime) <= 0) return current;
          return prev;
        }, null),
    [activeEvents, nowDateTime],
  );

  return (
    <Box bg="white">
      <Container
        maxW="container.2xl"
        as={VStack}
        p={0}
        align="stretch"
        spacing={[10, null, null, 40]}
        bg="white"
        dir={i18n.dir()}
        overflowX="hidden"
      >
        <InternationalNavbar />
        <InternationalContestHero nearestContest={nearestContest} />
        <InternationalContestFeatures />
        <InternationalContestAbout />
        <InternationalContestProcess />
        {activeEvents.length > 0 && <InternationalContestSteps events={activeEvents} />}
        {endedEvents.length > 0 && <InternationalContestEndedSteps events={endedEvents} />}
        <InternationalContestFaqs event={event} />
      </Container>
      <InternationalFooter bgColor="brand.900" />
    </Box>
  );
};

InternationalContestPage.fragments = {
  event: gql`
    fragment InternationalContestPageFragment on SingleEventNode {
      pk
      registration_deadline
      social_links {
        id
        type
        url
      }
      ...InternationalContestFaqsFragment
      ...InternationalContestStepsFragment
      ...InternationalContestEndedStepsFragment
    }
    ${InternationalContestFaqs.fragments.event}
    ${InternationalContestSteps.fragments.event}
    ${InternationalContestEndedSteps.fragments.event}
  `,
};
