import { Heading, VStack } from "@chakra-ui/react";
import { gql } from "graphql-request";
import { useTranslation } from "next-i18next";
import { InternationalContestFaqsFragment } from "@/gql-types";
import { FaqAccordion } from "@/common/components/FaqAccordion";

export const InternationalContestFaqs = ({ event }: { event: InternationalContestFaqsFragment }) => {
  const { t } = useTranslation("internationalContest");
  if (!event.faq_items || event.faq_items.length === 0) return null;

  return (
    <VStack align="center" my={[10, 20]} px={[8, null, null, null, 80]} py={12} spacing={8}>
      <VStack align="center">
        <Heading size="sm" color="brand.500">
          {t("landpage.faqs.faqs")}
        </Heading>
        <Heading textAlign="center" fontWeight="extrabold">
          {t("landpage.faqs.frequencyAskedQuestions")}
        </Heading>
      </VStack>
      <FaqAccordion faqItems={event.faq_items} bg="transparent" border="none" shadow="none" fluid />
    </VStack>
  );
};

InternationalContestFaqs.fragments = {
  event: gql`
    fragment InternationalContestFaqsFragment on SingleEventNode {
      faq_items {
        id
        question
        answer
      }
    }
  `,
};
