import NextImage from "next/image";
import { Box, Button, Center, Heading, Stack, Text } from "@chakra-ui/react";
import { useTranslation, Trans } from "next-i18next";

import HeroImage from "@/modules/international/contest/assets/images/hero.png";
import { QTimer } from "@/common/datetime";
import { InternationalContestMark } from "@/modules/international/contest/components/InternationalContestMark";
import { InternationalContestStepsFragment } from "@/gql-types";

interface InternationalContestHeroProps {
  nearestContest: InternationalContestStepsFragment["children"][0]["steps"][0];
}

export const InternationalContestHero = ({ nearestContest }: InternationalContestHeroProps) => {
  const { t, i18n } = useTranslation(["internationalContest"]);
  const dir = i18n.dir();

  return (
    <Stack
      direction={["column", null, null, "row"]}
      align={["center", null, null, "flex-start"]}
      my={20}
      justify="space-between"
      spacing={[20, null, null, 0]}
    >
      <Stack
        px={[4, null, null, null, 20]}
        spacing={16}
        align="center"
        justify={["center", "flex-start"]}
        flex={[1, null, null, 3]}
      >
        <Heading fontWeight="600" textAlign={["center", "start"]} w={["90%", "full"]}>
          <Trans i18nKey="internationalContest:landpage.hero.welcomeToTheMostExcellent">
            Welcome to the most excellent <InternationalContestMark>Programming</InternationalContestMark> contest...
          </Trans>
        </Heading>
        <Box w="full">
          <Heading fontWeight="extrabold" textAlign={["center", "start"]} size="md">
            {t("internationalContest:landpage.hero.hurryUp")}
          </Heading>
          {!!nearestContest && (
            <>
              <Text textAlign={["center", "start"]}>
                <Trans
                  i18nKey="internationalContest:landpage.hero.untilStartContest"
                  values={{ contest: nearestContest.title }}
                >
                  Until the start of the <strong>{nearestContest.title}</strong> contest
                </Trans>
              </Text>
              <Center
                mt={4}
                justifyContent={["center", "flex-start"]}
                sx={{
                  "& .time-number": {
                    borderBottom: "1px solid",
                    borderColor: "brand.500",
                    textAlign: ["center", "start"],
                    fontSize: 16,
                    fontWeight: "bold",
                    w: 16,
                    py: 2,
                    my: 2,
                  },
                  "& .time-label": {
                    textAlign: ["center", "start"],
                    fontSize: 12,
                    color: "brand.500",
                  },
                }}
              >
                <QTimer time={nearestContest.start_time} withColon withLabel showSeconds dir={dir} />
              </Center>
            </>
          )}
        </Box>
        <Stack w="full" spacing={6} direction={["column", "row"]} align={["center", "end"]}>
          <Button as="a" href="#contests" colorScheme="brand">
            {t("internationalContest:landpage.hero.seeContests")}
          </Button>
          <Button variant="link" as="a" href="#features" fontWeight="bold" color="brand.400">
            {t("internationalContest:landpage.hero.readMore")}
          </Button>
        </Stack>
      </Stack>
      <Center
        flex={[1, null, null, 4]}
        px={[10, 0]}
        pt={[0, null, null, 30]}
        position="relative"
        h="fit-content"
        sx={{
          "&::after": {
            content: "''",
            bg: "gray.100",
            transform: "rotate(-10deg)",
            w: ["90%", null, null, "100%"],
            h: ["90%", null, null, "100%"],
            position: "absolute",
            zIndex: 0,
          },
        }}
      >
        <NextImage alt="code cup hero" width={780} height={433} src={HeroImage} style={{ height: "auto", zIndex: 1 }} />
      </Center>
    </Stack>
  );
};
