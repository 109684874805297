import { ReactElement, SVGProps } from "react";
import { Box, BoxProps, Divider, Heading, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import PuzzleIcon from "@/modules/international/common/assets/icons/puzzle.svg";
import MessageIcon from "@/modules/international/common/assets/icons/message.svg";
import SchoolIcon from "@/modules/international/common/assets/icons/school.svg";

const FeatureItem = ({
  title,
  description,
  icon: Icon,
  ...rest
}: {
  title: string;
  description: string;
  icon: (props: SVGProps<SVGElement>) => ReactElement;
} & BoxProps) => (
  <Box px={[0, null, null, 10]} minH="full">
    <VStack p={8} h="full" {...rest} borderColor="brand.400 !important">
      <HStack align="center" spacing={4} mb={8}>
        <Icon />
        <Heading size="md" fontWeight="bold">
          {title}
        </Heading>
      </HStack>
      <Text textAlign="center" color="brand.900">
        {description}
      </Text>
    </VStack>
  </Box>
);

export const InternationalContestFeatures = () => {
  const { t } = useTranslation("internationalContest");

  return (
    <Stack id="features" direction="column" py={8} px={[8, null, null, null, 20]}>
      <Box mb={10}>
        <Heading size="sm" color="brand.500">
          {t("internationalContest:landpage.features.whyUs")}
        </Heading>
        <Heading fontWeight="bold">{t("internationalContest:landpage.features.thingsYouCanFind")}</Heading>
      </Box>
      <Stack direction={["column", null, null, "row"]} justify="space-between" spacing={0}>
        <FeatureItem
          title={t("landpage.features.firstItem.title")}
          description={t("landpage.features.firstItem.description")}
          icon={PuzzleIcon}
          borderBottom="1px"
          borderRight={["1px", null, null, "0"]}
        />
        <Divider orientation="vertical" borderColor="brand.400" h={[0, null, null, 300]} opacity={1} />
        <FeatureItem
          title={t("landpage.features.secondItem.title")}
          description={t("landpage.features.secondItem.description")}
          icon={MessageIcon}
          borderTop={["0", null, null, "1px"]}
          borderLeft={["1px", null, null, "0"]}
        />
        <Divider orientation="vertical" borderColor="brand.400" h={[0, null, null, 300]} opacity={1} />
        <FeatureItem
          title={t("landpage.features.thirdItem.title")}
          description={t("landpage.features.thirdItem.description")}
          icon={SchoolIcon}
          borderBottom={["0", null, null, "1px"]}
          borderTop={["1px", null, null, "0px"]}
          borderRight={["1px", null, null, "0"]}
        />
      </Stack>
    </Stack>
  );
};
