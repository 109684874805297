import { Box, Center, Heading, Stack, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Fragment } from "react";

import ArrowIcon from "@/modules/international/common/assets/icons/arrow.svg";

const StepItem = ({ title, index }: { title: string; index: number }) => {
  const { t } = useTranslation("internationalContest");

  return (
    <Box bg="white" rounded="sm" boxShadow="md" flex={1} w="full" p={[6, 12]}>
      <Heading size="md" fontWeight="bold" color="brand.900" textAlign="center">
        {`${index}. ${t(title)}`}
      </Heading>
    </Box>
  );
};

const STEPS = [
  { title: "landpage.process.register" },
  { title: "landpage.process.warmupContest" },
  { title: "landpage.process.mainContes" },
  { title: "landpage.process.onlineWebinar" },
];

export const InternationalContestProcess = () => {
  const { t, i18n } = useTranslation("internationalContest");
  const dir = i18n.dir();

  return (
    <VStack
      id="time-schedule"
      bg="brand.400"
      my={[10, 40]}
      px={[8, null, null, 20]}
      py={[8, null, null, 12]}
      spacing={[8, null, null, 12]}
    >
      <VStack color="white">
        <Heading size="sm" fontWeight="normal">
          {t("landpage.process.timeSchedule")}
        </Heading>
        <Heading fontWeight="extrabold">{t("landpage.process.ourContestProcess")}</Heading>
      </VStack>
      <Stack direction={["column", null, null, "row"]} w="full">
        {STEPS.map(({ title }, index) => (
          <Fragment key={title}>
            {index !== 0 && (
              <Center
                transform={["rotate(90deg) scale(0.7)", null, null, dir === "rtl" ? "scale(-1)" : "none"]}
                h={["16", null, null, "auto"]}
                px={2}
              >
                <ArrowIcon />
              </Center>
            )}
            <StepItem title={title} index={index + 1} />
          </Fragment>
        ))}
      </Stack>
    </VStack>
  );
};
