import { Button, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { gql } from "graphql-request";
import { useTranslation } from "next-i18next";
import { Link as ChakraLink } from "@chakra-ui/next-js";
import { Temporal, Intl as TemporlaIntl } from "temporal-polyfill";
import { useContext } from "react";
import { InternationalContestEndedStepsFragment } from "@/gql-types";
import { DateTimeFormatter } from "@/common/datetime";
import { InternationalContestUserTimezoneContext } from "../../contexts/InternationalContestTimezoneContext";

type LocalesType = "fa" | "en" | "ar";
const locales: Record<LocalesType, string> = {
  fa: "fa-IR",
  en: "en-US",
  ar: "ar-EG",
};

interface InternationalContestEndedStepItemProps {
  event: InternationalContestEndedStepsFragment["children"][number];
}

const InternationalContestEndedStepItem = ({ event }: InternationalContestEndedStepItemProps) => {
  const { t } = useTranslation("internationalContest");
  const userTimezone = useContext(InternationalContestUserTimezoneContext);

  const getContestDuration = (startTime: string, finishTime: string) => {
    const duration = Temporal.PlainDateTime.from(startTime).until(finishTime);
    return `${duration.hours.toLocaleString(locales.en)}:${duration.minutes
      .toLocaleString(locales.en)
      .padStart(2, "0")}:${duration.seconds.toLocaleString(locales.en).padStart(2, "00")}`;
  };

  return (
    <>
      {event.steps.map(({ id, title, cta, start_time, finish_time, user_count }, index, { length }) => (
        <Tr
          key={id}
          display={["block", null, null, "table-row"]}
          borderBottom={index === length - 1 ? "solid 1px" : "none"}
          borderColor="blackAlpha.400"
          sx={{
            "& > td": {
              px: [0, null, null, 4],
              my: [4, null, null, 0],
              fontSize: "md",
              fontWeight: "normal",
            },
            "& > td:not(:first-child)": {
              display: ["flex", null, null, "table-cell"],
              justifyContent: "space-between",
              alignItems: "center",
              px: [0, null, null, 4],
              my: [2, null, null, 0],
              "&::before": {
                content: ["attr(data-label)", null, null, '""'],
                fontWeight: "bold",
              },
            },
            "& > td:first-child": {
              fontWeight: "bold",
              fontSize: "lg",
              borderEnd: ["none", null, null, "1px solid"],
              borderInlineEndColor: ["unset", null, null, "blackAlpha.400"],
            },
          }}
        >
          <Td data-label="" scope="row">
            {title}
          </Td>
          <Td data-label={t("landpage.steps.startTime")}>
            {DateTimeFormatter.format(
              start_time,
              {
                ...DateTimeFormatter.DATETIME_SHORT,
                hour12: false,
                timeZoneName: "short",
                timeZone: userTimezone || TemporlaIntl.DateTimeFormat().resolvedOptions().timeZone,
              },
              locales.en,
            )}
          </Td>
          <Td data-label={t("landpage.steps.duration")}>{getContestDuration(start_time, finish_time)}</Td>
          <Td data-label={t("landpage.steps.compatitors")}>{user_count}</Td>
          <Td data-label={t("landpage.steps.action")}>
            <Button size="sm" as={ChakraLink} href={cta.url} variant="outline">
              {t("landpage.steps.viewResults")}
            </Button>
          </Td>
        </Tr>
      ))}
    </>
  );
};

interface InternationalContestEndedStepsProps {
  events: InternationalContestEndedStepsFragment["children"];
}

export const InternationalContestEndedSteps = ({ events }: InternationalContestEndedStepsProps) => {
  const { t } = useTranslation("internationalContest");

  return (
    <VStack id="ended-contests" px={[8, null, null, null, 60]} py={8} spacing={12} align="center">
      <VStack align="center">
        <Heading size="sm" color="brand.500" mb={2}>
          {t("landpage.steps.pastContests")}
        </Heading>
        <Heading textAlign="center" fontWeight="extrabold">
          {t("landpage.steps.seeTheContestsHistory")}
        </Heading>
      </VStack>
      <VStack w="full" spacing={14}>
        <TableContainer w="full">
          <Table variant="unstyled" colorScheme="blackAlpha" size="sm">
            <Thead borderBottom="1px" borderColor="blackAlpha.400" display={["none", null, null, "table-header-group"]}>
              <Tr>
                <Th w={0} borderEnd="1px" borderColor="blackAlpha.400" p={4} />
                <Th scope="col" fontSize="md" fontWeight="medium" p={4}>
                  {t("landpage.steps.startTime")}
                </Th>
                <Th scope="col" fontSize="md" fontWeight="medium" p={4}>
                  {t("landpage.steps.duration")}
                </Th>
                <Th scope="col" fontSize="md" fontWeight="medium" p={4}>
                  {t("landpage.steps.compatitors")}
                </Th>
                <Th scope="col" fontSize="md" fontWeight="medium" p={4}>
                  {t("landpage.steps.action")}
                </Th>
              </Tr>
            </Thead>
            <Tbody
              sx={{
                "& > tr:last-child": {
                  borderBottom: "none",
                },
              }}
            >
              {events.map((event) => (
                <InternationalContestEndedStepItem key={event.id} event={event} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
    </VStack>
  );
};

InternationalContestEndedSteps.fragments = {
  event: gql`
    fragment InternationalContestEndedStepsFragment on SingleEventNode {
      children {
        id
        slug
        user_count
        title
        steps_description
        steps {
          id
          title
          finish_time
          start_time
          user_count
          cta {
            url
          }
        }
      }
    }
  `,
};
