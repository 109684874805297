import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as ChakraLink, Image as ChakraImage } from "@chakra-ui/next-js";

import { mdiAccount, mdiAccountEdit, mdiExitToApp, mdiMenu } from "@mdi/js";
import { useTranslation } from "next-i18next";
import { useContext, useRef, useState } from "react";
import { axiosClient } from "apis/rest/axios-client";
import { MdIcon } from "@/common/icons/MdIcon";
import { useSSRBreakpointValue } from "@/common/hooks/useSSRBreakpointValue";

import { urls } from "@/reverse/urls";
import { useGlobalServerValues } from "@/common/components/GlobalServerValuesProvider";
import { InternationalSiteContext } from "@/modules/international/common/contexts/InternationalSiteContext";
import { BrandLogoMapped } from "@/modules/international/common/constants";

export const InternationalNavbar = () => {
  const isDesktop = useSSRBreakpointValue([false, null, null, true]);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation(["common", "internationalContest"]);
  const dir = i18n.dir();
  const {
    currentUser: { is_authenticated, first_name, last_name },
  } = useGlobalServerValues();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const { site } = useContext(InternationalSiteContext);
  const logoutHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosClient.post<{ success: boolean }>(
        urls["international:international_auth:logout_api"](),
      );
      if (data.success) window.location.reload();
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <>
      <HStack
        pos="relative"
        py={8}
        px={[4, null, null, null, 20]}
        justify={["space-between", null, null, "flex-start"]}
      >
        {isDesktop ? (
          <HStack w="full" justify="space-between" align="center" spacing={[8, null, null, 8, 16]}>
            <HStack spacing={12}>
              <Box>
                <ChakraImage
                  src={BrandLogoMapped[site.domain]}
                  alt={site.name}
                  width={200}
                  style={{ objectFit: "cover" }}
                />
              </Box>
              <Link href="#about" color="brand.800">
                {t("internationalContest:landpage.menu.aboutContest")}
              </Link>
              <Link href="#time-schedule" color="brand.800">
                {t("internationalContest:landpage.menu.timeSchedule")}
              </Link>
            </HStack>
            {is_authenticated ? (
              <Menu>
                <MenuButton
                  as={Button}
                  aria-label="Options"
                  leftIcon={<MdIcon color="brand.500" icon={mdiAccount} boxSize={6} />}
                  variant="outline"
                >
                  {`${first_name} ${last_name}`}
                </MenuButton>
                <MenuList>
                  <MenuItem
                    as={ChakraLink}
                    href={urls["international:edit_profile_page"]()}
                    _hover={{ textDecoration: "none" }}
                    icon={<MdIcon color="brand.500" icon={mdiAccountEdit} boxSize={6} />}
                  >
                    {t("internationalContest:landpage.menu.editProfile")}
                  </MenuItem>
                  <MenuItem icon={<MdIcon color="brand.500" icon={mdiExitToApp} boxSize={6} />} onClick={onOpen}>
                    {t("internationalContest:landpage.menu.logout")}
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <Button
                as={ChakraLink}
                href={urls["international:international_auth:login_page"]()}
                colorScheme="brand"
                variant="outline"
                px={20}
              >
                {t("internationalContest:landpage.auth.login")}
              </Button>
            )}
          </HStack>
        ) : (
          <>
            <ChakraImage
              src={BrandLogoMapped[site.domain]}
              alt={site.name}
              width={120}
              style={{ objectFit: "cover" }}
            />
            <Menu>
              <MenuButton
                colorScheme="gray"
                as={IconButton}
                aria-label="Options"
                icon={<MdIcon icon={mdiMenu} />}
                fontSize={32}
                color="gray.800"
                _hover={{ color: "gray.500", bg: "whiteAlpha.500" }}
                _active={{ color: "gray.500", bg: "whiteAlpha.600" }}
                variant="ghost"
              />
              <MenuList w="100vw" fontSize="xl" zIndex={200} sx={{ "> a": { color: "gray.600" } }}>
                <MenuItem as="a" href="#about">
                  {t("internationalContest:landpage.menu.aboutContest")}
                </MenuItem>
                <MenuItem as="a" href="#time-schedule">
                  {t("internationalContest:landpage.menu.timeSchedule")}
                </MenuItem>
                {is_authenticated && (
                  <>
                    <MenuDivider />
                    <MenuGroup title={t("internationalContest:landpage.menu.profile")}>
                      <MenuItem
                        as={ChakraLink}
                        _hover={{ textDecoration: "none" }}
                        href={urls["international:edit_profile_page"]()}
                        icon={<MdIcon color="brand.500" icon={mdiAccountEdit} boxSize={6} />}
                      >
                        {t("internationalContest:landpage.menu.editProfile")}
                      </MenuItem>
                      <MenuItem icon={<MdIcon color="brand.500" icon={mdiExitToApp} boxSize={6} />} onClick={onOpen}>
                        {t("internationalContest:landpage.menu.logout")}
                      </MenuItem>
                    </MenuGroup>
                  </>
                )}
              </MenuList>
            </Menu>
          </>
        )}
      </HStack>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent dir={dir}>
          <AlertDialogHeader> {t("internationalContest:landpage.menu.logout")}</AlertDialogHeader>

          <AlertDialogBody>{t("internationalContest:landpage.menu.youAreLoggingOut")}</AlertDialogBody>
          <AlertDialogFooter as={HStack}>
            <Button ref={cancelRef} onClick={onClose}>
              {t("internationalContest:landpage.menu.cancel")}
            </Button>
            <Button colorScheme="red" ml={3} onClick={logoutHandler} isLoading={isLoading}>
              {t("internationalContest:landpage.menu.yesLogout")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
