import { useState, useRef, useEffect, ReactNode, ComponentType } from "react";
import styled from "@emotion/styled";
import { Temporal } from "temporal-polyfill";
import { HStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useGlobalServerValues } from "@/common/components/GlobalServerValuesProvider";

const TimeBlock = styled.div`
  line-height: 1;
  display: inline-block;
  text-align: center;
`;

const BlockTimeLabel = styled.div`
  font-size: calc(5px + 0.35em);
`;

const InlineTimeLabel = styled.span`
  font-size: 1em;
  padding-right: 2px;
`;

const BaseTimeNumber = styled.div`
  font-size: 1em;
  white-space: nowrap;
`;

const SimpleTimeNumber = styled(BaseTimeNumber)`
  margin-bottom: 2px;
  display: inline-block;
`;

const BorderedTimeNumber = styled(BaseTimeNumber)`
  border-radius: 3px;
  margin-bottom: 6px;
  padding: 0.5em;
  width: 35px;
  border: 1px solid #ccc;
  white-space: nowrap;
`;

const SeparatorSpan = styled.span`
  font-size: 1em;
  padding-right: 1px;
  padding-left: 1px;
  display: inline-block;
`;

const locales: Record<string, string> = {
  fa: "fa-IR",
  en: "en-US",
  ar: "ar-EG",
};

const TimeNumber = ({ bordered, children, style }: { bordered?: boolean; children: ReactNode; style: any }) =>
  bordered ? (
    <BorderedTimeNumber className="time-number" style={style}>
      {children}
    </BorderedTimeNumber>
  ) : (
    <SimpleTimeNumber className="time-number" style={style}>
      {children}
    </SimpleTimeNumber>
  );

const TimeLabel = ({ inline, children }: { inline?: boolean; children: ReactNode }) =>
  inline ? (
    <InlineTimeLabel className="time-label">{children}</InlineTimeLabel>
  ) : (
    <BlockTimeLabel className="time-label">{children}</BlockTimeLabel>
  );

type QTimerProps = {
  time: string | Temporal.Instant;
  showSeconds?: boolean;
  dir?: string;
  onFinish?: () => void;
  hideOnFinish?: boolean;
  withBorder?: boolean;
  withColon?: boolean;
  withAnd?: boolean;
  withLabel?: boolean;
  withColor?: boolean;
  inline?: boolean;
  showDaysEvenIfZero?: boolean;
  showHoursEvenIfZero?: boolean;
  showMinutesEvenIfZero?: boolean;
  showMinutes?: boolean;
  LabelComponent?: ComponentType<any>;
};

export const QTimer = ({
  time,
  showSeconds,
  dir,
  onFinish,
  hideOnFinish,
  withBorder,
  withColon,
  withAnd,
  withLabel = true,
  showMinutes = true,
  inline = false,
  showDaysEvenIfZero = false,
  showHoursEvenIfZero = false,
  showMinutesEvenIfZero = false,
  withColor = true,
  LabelComponent = TimeLabel,
}: QTimerProps) => {
  const { serverTime } = useGlobalServerValues();
  const [remaining, setRemaining] = useState<Temporal.Duration>(new Temporal.Duration());
  const delta = useRef<Temporal.Duration>(new Temporal.Duration());
  const timeNumberStyle = inline && withColor ? { color: "var(--chakra-colors-pink-300)", fontWeight: "700" } : {};
  const { t, i18n } = useTranslation("common");
  const locale = locales[i18n?.language || "fa"];

  useEffect(() => {
    if (serverTime) {
      delta.current = Temporal.Now.instant().since(serverTime);
    } else {
      delta.current = new Temporal.Duration();
    }

    let timer: ReturnType<typeof setInterval>;
    const timerFunc = () => {
      const now = Temporal.Now.instant().subtract(delta.current);
      const newRemaining = Temporal.Duration.from({
        milliseconds: Math.max(0, Temporal.Instant.from(time).epochMilliseconds - now.epochMilliseconds),
      }).round({ largestUnit: "day" });
      if (newRemaining.total("seconds") < 1 && onFinish) {
        onFinish();
        clearTimeout(timer);
      }
      setRemaining(newRemaining);
    };

    timerFunc();
    timer = setInterval(timerFunc, 1000);

    return () => clearInterval(timer);
  }, [onFinish, serverTime, time]);

  if (remaining.total("seconds") < 1 && hideOnFinish) return null;

  return (
    <div>
      <HStack dir={dir || "ltr"}>
        {(showDaysEvenIfZero || remaining.days > 0) && (
          <>
            <TimeBlock className="time-block">
              <TimeNumber bordered={withBorder} style={timeNumberStyle}>
                {remaining.days.toLocaleString(locale)}
              </TimeNumber>
              {withLabel && <LabelComponent inline={inline}>{t("روز")}</LabelComponent>}
            </TimeBlock>
            {withColon && remaining.hours > 0 && <SeparatorSpan className="separator-colon">:</SeparatorSpan>}
            {withAnd && remaining.hours > 0 && <SeparatorSpan>و</SeparatorSpan>}
          </>
        )}
        {(showHoursEvenIfZero || remaining.hours > 0) && (
          <TimeBlock className="time-block">
            <TimeNumber bordered={withBorder} style={timeNumberStyle}>
              {remaining.hours.toLocaleString(locale)}
            </TimeNumber>
            {withLabel && <LabelComponent inline={inline}>{t("ساعت")}</LabelComponent>}
          </TimeBlock>
        )}
        {showMinutes && withColon && (showHoursEvenIfZero || remaining.hours > 0) && (
          <SeparatorSpan className="separator-colon">:</SeparatorSpan>
        )}
        {showMinutes && withAnd && (showHoursEvenIfZero || remaining.hours > 0) && <SeparatorSpan>و</SeparatorSpan>}
        {showMinutes && (showMinutesEvenIfZero || remaining.minutes > 0) && (
          <TimeBlock className="time-block">
            <TimeNumber bordered={withBorder} style={timeNumberStyle}>
              {remaining.minutes.toLocaleString(locale)}
            </TimeNumber>
            {withLabel && <LabelComponent inline={inline}>{t("دقیقه")}</LabelComponent>}
          </TimeBlock>
        )}
        {showSeconds && withColon && (showMinutesEvenIfZero || remaining.minutes > 0) && (
          <SeparatorSpan className="separator-colon">:</SeparatorSpan>
        )}
        {showSeconds && withAnd && (showMinutesEvenIfZero || remaining.minutes > 0) && <SeparatorSpan>و</SeparatorSpan>}
        {showSeconds && remaining.seconds >= 0 && (
          <TimeBlock className="time-block">
            <TimeNumber bordered={withBorder} style={timeNumberStyle}>
              {remaining.seconds.toLocaleString(locale)}
            </TimeNumber>
            {withLabel && <LabelComponent inline={inline}>{t("ثانیه")}</LabelComponent>}
          </TimeBlock>
        )}
      </HStack>
    </div>
  );
};
