import NextImage from "next/image";
import { Box, Button, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import AboutImage from "@/modules/international/contest/assets/images/about.png";

export const InternationalContestAbout = () => {
  const { t, i18n } = useTranslation("internationalContest");
  const dir = i18n.dir();

  return (
    <Stack
      id="about"
      direction={["column", null, null, "row"]}
      align="center"
      my={[10, null, null, 40]}
      px={[8, null, null, null, 20]}
      spacing={14}
    >
      <NextImage
        alt="About"
        width={450}
        height={450}
        src={AboutImage}
        style={{
          maxWidth: "100%",
          height: "auto",
          flex: 1,
          transform: dir === "rtl" ? "scale(-1,1)" : "none",
        }}
      />
      <VStack align="flex-start" flex={1} spacing={8}>
        <Box>
          <Heading size="sm" fontWeight="normal" color="brand.400" mb={2}>
            {t("landpage.about.aboutThisCompetition")}
          </Heading>
          <Heading size="2xl" fontWeight="extrabold">
            {t("landpage.about.whereYouCan")}
          </Heading>
        </Box>
        <Box>
          <Text>{t("landpage.about.p1")}</Text>
          <Text>{t("landpage.about.p2")}</Text>
          <Text>{t("landpage.about.p3")}</Text>
          <Text>{t("landpage.about.p4")}</Text>
        </Box>
        <Button as="a" href="#contests" colorScheme="brand">
          {t("landpage.hero.seeContests")}
        </Button>
      </VStack>
    </Stack>
  );
};
