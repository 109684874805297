import { Link } from "@chakra-ui/react";

import { mdiFacebook, mdiGithub, mdiGooglePlus, mdiInstagram, mdiLinkedin, mdiTwitter, mdiYoutube } from "@mdi/js";
import { mdiTelegramPlane } from "@/common/icons/extra_icons";

import { MdIcon, MdIconProps } from "@/common/icons/MdIcon";

enum SocialNetworkType {
  LinkedIn = "linkedin",
  Facebook = "facebook",
  GitHub = "github",
  Twitter = "twitter",
  Instagram = "instagram",
  Telegram = "telegram",
  YouTube = "youtube",
  GooglePlus = "google plus",
}

const SOCIAL_NETWORK_TYPES_TO_ICON = {
  [SocialNetworkType.LinkedIn]: mdiLinkedin,
  [SocialNetworkType.Facebook]: mdiFacebook,
  [SocialNetworkType.GitHub]: mdiGithub,
  [SocialNetworkType.Twitter]: mdiTwitter,
  [SocialNetworkType.Instagram]: mdiInstagram,
  [SocialNetworkType.Telegram]: mdiTelegramPlane,
  [SocialNetworkType.YouTube]: mdiYoutube,
  [SocialNetworkType.GooglePlus]: mdiGooglePlus,
};

export const SocialNetworkIcon = ({ url, type, ...props }: Partial<MdIconProps> & { url: string; type: string }) => {
  const icon = SOCIAL_NETWORK_TYPES_TO_ICON[type];
  return (
    <Link rel="noreferrer nofollow" href={url} target="_blank">
      <MdIcon _hover={{ color: "brand.600" }} icon={icon} boxSize={6} {...props} />
    </Link>
  );
};
