import {
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { gql } from "graphql-request";
import { Trans, useTranslation } from "next-i18next";
import { Link as ChakraLink } from "@chakra-ui/next-js";
import { Temporal, Intl as TemporlaIntl } from "temporal-polyfill";
import { CheckIcon } from "@chakra-ui/icons";
import { useContext, useState } from "react";
import { axiosClient } from "apis/rest/axios-client";
import { useRouter } from "next/router";
import MainContestIcon from "@/modules/international/common/assets/icons/main-contest.svg";
import WarmupIcon from "@/modules/international/common/assets/icons/warmup.svg";
import CompetitorsIcon from "@/modules/international/common/assets/icons/competitors.svg";
import { InternationalContestStepsFragment } from "@/gql-types";
import { DateTimeFormatter, QTimer } from "@/common/datetime";
import { RichText } from "@/common/components/RichText";
import { urls } from "@/reverse/urls";

import { useGlobalServerValues } from "@/common/components/GlobalServerValuesProvider";
import { InternationalContestUserTimezoneContext } from "../../contexts/InternationalContestTimezoneContext";
import { toUserTimezone } from "../../../common/utils";

type LocalesType = "fa" | "en" | "ar";
const locales: Record<LocalesType, string> = {
  fa: "fa-IR",
  en: "en-US",
  ar: "ar-EG",
};

interface InternationalContestStepItemProps {
  event: InternationalContestStepsFragment["children"][number];
  registeredContests: string;
}

const InternationalContestStepItem = ({ event, registeredContests }: InternationalContestStepItemProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { onClose, onOpen, isOpen } = useDisclosure({ defaultIsOpen: false });
  const { t, i18n } = useTranslation("internationalContest");
  const dir = i18n.dir();
  const locale = locales[i18n.language || "fa"];
  const {
    currentUser: { is_authenticated },
  } = useGlobalServerValues();
  const currentDate = Temporal.Now.plainDateTime("iso8601");
  const userTimezone = useContext(InternationalContestUserTimezoneContext);

  const convertToGoogleFormat = (time: string) =>
    Temporal.PlainDateTime.from(time).toString().replaceAll("-", "").replaceAll(":", "");

  const getGoogleCalenderLink = (title: string, start_time: string, finish_time: string) =>
    // eslint-disable-next-line max-len
    `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${title}&details=https%3A%2F%2F${
      i18n.language === "en" ? "codern.org" : "sibaq.org"
    }&dates=${convertToGoogleFormat(start_time)}Z/${convertToGoogleFormat(finish_time)}Z`;

  const getContestDuration = (startTime: string, finishTime: string) => {
    const duration = Temporal.PlainDateTime.from(startTime).until(finishTime);
    return `${duration.hours.toLocaleString(locales.en)}:${duration.minutes
      .toLocaleString(locales.en)
      .padStart(2, "0")}:${duration.seconds.toLocaleString(locales.en).padStart(2, "00")}`;
  };

  const isOngoing = (startTime: string, finishTime: string) =>
    Temporal.PlainDateTime.compare(currentDate, toUserTimezone(finishTime).toString()) <= 0 &&
    Temporal.PlainDateTime.compare(currentDate, toUserTimezone(startTime).toString()) >= 0;

  const getContestTimeToStart = (startTime: string, finishTime: string) => {
    const remainingTime = Temporal.PlainDateTime.from(currentDate).until(toUserTimezone(startTime));
    if (Temporal.PlainDateTime.compare(currentDate, toUserTimezone(finishTime).toString()) >= 0)
      return t("landpage.steps.ended");
    if (isOngoing(startTime, finishTime)) return t("landpage.steps.running");
    if (remainingTime.days >= 1) return `${remainingTime.days.toLocaleString(locales.en)} ${t("landpage.steps.days")}`;
    return <QTimer time={toUserTimezone(startTime).toString()} showSeconds withColon dir={dir} />;
  };

  const eventRegisterHandler = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosClient.post<{ success: boolean }>(
        urls["international:international_contest:event_registration_api"](event.slug),
      );
      if (data.success) router.replace(router.asPath, undefined, { scroll: false });
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const getActionButton = ({
    start_time,
    finish_time,
    cta: { url },
    title,
  }: InternationalContestStepItemProps["event"]["steps"][number]) => {
    const contestIsOngoing = isOngoing(start_time, finish_time);
    const contestIsEnded = Temporal.PlainDateTime.compare(currentDate, toUserTimezone(finish_time).toString()) > 0;
    const contestIsComingSoon = !contestIsOngoing && !contestIsEnded;

    let buttonText = t("landpage.steps.addToGoogleCalender");
    if (contestIsOngoing) buttonText = t("landpage.steps.startContest");
    else if (contestIsEnded) buttonText = t("landpage.steps.viewResults");

    return (
      <Button
        size="sm"
        as={ChakraLink}
        {...(contestIsComingSoon && { target: "_blank", rel: "noreferrer nofollow" })}
        href={contestIsComingSoon ? getGoogleCalenderLink(title, start_time, finish_time) : url}
        variant="outline"
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <>
      <VStack borderStart="1px solid" borderColor="brand.400" p={8} spacing={8} w="full">
        <Stack
          direction={["column", "row"]}
          justify="space-between"
          align={["flex-start", "flex-end"]}
          spacing={8}
          w="full"
        >
          <VStack w="full" align="flex-start" spacing={4}>
            <Heading fontWeight="bold">{event.title}</Heading>
            <RichText
              dangerouslySetInnerHTML={{ __html: event.steps_description }}
              maxW={["full", null, null, "85%"]}
            />
            <Stack w="full" direction={["column", "row"]} wrap="wrap" spacing={[4, 14]}>
              <HStack>
                <CompetitorsIcon />
                <Text>
                  {t("landpage.steps.compatitors")}: <strong>{event.user_count}</strong>
                </Text>
              </HStack>
            </Stack>
          </VStack>
          {event.user_has_participated && is_authenticated && (
            <Tag size="lg" colorScheme="brand" minW="auto">
              <TagLeftIcon as={CheckIcon} />
              <TagLabel>{t("landpage.steps.registered")}</TagLabel>
            </Tag>
          )}
          {!event.user_has_participated && is_authenticated && (
            <Button colorScheme="brand" variant="outline" px={16} onClick={onOpen}>
              {t("landpage.steps.register")}
            </Button>
          )}
          {!event.user_has_participated && !is_authenticated && (
            <Button
              as={ChakraLink}
              href={`${urls["international:international_auth:signup_page"]()}?contest=${event.slug}`}
              colorScheme="brand"
              variant="outline"
              px={16}
            >
              {event.user_has_participated ? t("landpage.steps.registered") : t("landpage.steps.register")}
            </Button>
          )}
        </Stack>
        <TableContainer w="full">
          <Table variant="unstyled" colorScheme="blackAlpha" size="sm">
            <Thead borderBottom="1px" borderColor="blackAlpha.400" display={["none", null, null, "table-header-group"]}>
              <Tr>
                <Th w={0} borderEnd="1px" borderColor="blackAlpha.400" p={4} />
                <Th scope="col" fontSize="md" fontWeight="medium" p={4}>
                  {t("landpage.steps.timeToStart")}
                </Th>
                <Th scope="col" fontSize="md" fontWeight="medium" p={4}>
                  {t("landpage.steps.startTime")}
                </Th>
                <Th scope="col" fontSize="md" fontWeight="medium" p={4}>
                  {t("landpage.steps.duration")}
                </Th>
                {is_authenticated && event.user_has_participated && (
                  <Th scope="col" fontSize="md" fontWeight="medium" p={4}>
                    {t("landpage.steps.action")}
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {event.steps.map((contest) => (
                <Tr
                  key={contest.id}
                  display={["block", null, null, "table-row"]}
                  sx={{
                    "& > td": {
                      px: [0, null, null, 4],
                      my: [4, null, null, 0],
                      fontSize: "md",
                      fontWeight: "normal",
                    },
                    "& > td:not(:first-child)": {
                      display: ["flex", null, null, "table-cell"],
                      justifyContent: "space-between",
                      alignItems: "center",
                      px: [0, null, null, 4],
                      my: [2, null, null, 0],
                      "&::before": {
                        content: ["attr(data-label)", null, null, '""'],
                        fontWeight: "bold",
                      },
                    },
                    "& > td:first-child": {
                      fontWeight: "bold",
                      fontSize: "lg",
                      borderEnd: ["none", null, null, "1px solid"],
                      borderInlineEndColor: ["unset", null, null, "blackAlpha.400"],
                    },
                  }}
                >
                  <Td data-label="" scope="row">
                    {contest.title}
                  </Td>
                  <Td data-label={t("landpage.steps.timeToStart")}>
                    {getContestTimeToStart(contest.start_time, contest.finish_time)}
                  </Td>
                  <Td data-label={t("landpage.steps.startTime")}>
                    {DateTimeFormatter.format(
                      contest.start_time,
                      {
                        ...DateTimeFormatter.DATETIME_SHORT,
                        hour12: false,
                        timeZoneName: "short",
                        timeZone: userTimezone || TemporlaIntl.DateTimeFormat().resolvedOptions().timeZone,
                      },
                      locales.en,
                    )}
                  </Td>
                  <Td data-label={t("landpage.steps.duration")}>
                    {getContestDuration(contest.start_time, contest.finish_time)}
                  </Td>
                  {is_authenticated && event.user_has_participated && (
                    <Td data-label={t("landpage.steps.action")}>{getActionButton(contest)}</Td>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent dir={dir}>
          <ModalBody p={6}>
            <Text fontSize="xl">
              <Trans
                i18nKey="internationalContest:landpage.steps.youAreAlreadyRegisterd"
                values={{ contest: event.title, registeredContests }}
              >
                You are already registered in <strong>{registeredContests}</strong> contests. Register in{" "}
                <strong>{event.title}</strong> contest?
              </Trans>
            </Text>

            <VStack w="full" wrap="wrap" spacing={2} align="flex-start" my={4}>
              <HStack>
                <CompetitorsIcon />
                <Text>
                  {t("landpage.steps.compatitors")}: <strong>{event.user_count}</strong>
                </Text>
              </HStack>
              {event.steps?.[0] && (
                <HStack>
                  <WarmupIcon />
                  <Text>
                    {t("landpage.steps.warmupContest")}:{" "}
                    <strong>
                      {DateTimeFormatter.format(
                        event.steps[0].start_time,
                        {
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                          timeZoneName: "short",
                          timeZone: TemporlaIntl.DateTimeFormat().resolvedOptions().timeZone,
                        },
                        locale,
                      )}
                    </strong>
                  </Text>
                </HStack>
              )}
              {event.steps?.[1] && (
                <HStack>
                  <MainContestIcon />
                  <Text>
                    {t("landpage.steps.mainContest")}:{" "}
                    <strong>
                      {DateTimeFormatter.format(
                        event.steps[1].start_time,
                        {
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                          timeZoneName: "short",
                          timeZone: TemporlaIntl.DateTimeFormat().resolvedOptions().timeZone,
                        },
                        locale,
                      )}
                    </strong>
                  </Text>
                </HStack>
              )}
            </VStack>
            <HStack>
              <Button colorScheme="brand" mr={3} flex={1} onClick={eventRegisterHandler} isLoading={isLoading}>
                {t("landpage.steps.register")}
              </Button>
              <Button variant="outline" colorScheme="brand" flex={1} onClick={onClose}>
                {t("landpage.steps.close")}
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

interface InternationalContestStepsProps {
  events: InternationalContestStepsFragment["children"];
}

export const InternationalContestSteps = ({ events }: InternationalContestStepsProps) => {
  const { t } = useTranslation("internationalContest");
  const registeredContests = events
    .filter((event) => event.user_has_participated)
    .map((event) => event.title)
    .join(", ");

  return (
    <VStack id="contests" px={[8, null, null, null, 60]} py={8} spacing={12} align="center">
      <VStack align="center">
        <Heading size="sm" color="brand.500" mb={2}>
          {t("landpage.steps.ourContests")}
        </Heading>
        <Heading textAlign="center" fontWeight="extrabold">
          {t("landpage.steps.contestsWillBe")}
        </Heading>
      </VStack>
      <VStack w="full" spacing={14}>
        {events.map((event) => (
          <InternationalContestStepItem key={event.id} event={event} registeredContests={registeredContests} />
        ))}
      </VStack>
    </VStack>
  );
};

InternationalContestSteps.fragments = {
  event: gql`
    fragment InternationalContestStepsFragment on SingleEventNode {
      children {
        id
        slug
        user_count
        title
        steps_description
        user_has_participated
        steps {
          id
          title
          start_time
          finish_time
          cta {
            url
            text
            icon
          }
        }
      }
    }
  `,
};
