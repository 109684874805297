import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { gql } from "graphql-request";
import { createServerSideGraphQLClient } from "graphql/server-side-client";
import Head from "next/head";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { InternationalContestPage } from "@/modules/international/contest/pages/InternationalContestPage";
import { TS_RLM } from "@/modules/talent/magnet/common/utils";
import { InternationalContestPageQuery } from "@/gql-types";
import { getGlobalServerValues, GlobalServerValuesProp } from "@/common/components/GlobalServerValuesProvider";

import { InternationalLayout } from "@/modules/international/contest/components/InternationalLayout";
import { InternationalContestTimezoneProvider } from "@/modules/international/contest/contexts/InternationalContestTimezoneContext";

interface PageProps {
  internationalSite: InternationalContestPageQuery["international"]["international_site"];
  userTimezone: InternationalContestPageQuery["international"]["profile"]["timezone"]["name"];
}

const Page = ({ internationalSite, userTimezone }: PageProps) => (
  <InternationalLayout internationalSite={internationalSite}>
    <Head>
      <title>{`${internationalSite.event.title} ${TS_RLM} ${internationalSite.site.name}`}</title>
    </Head>
    <InternationalContestTimezoneProvider userTimezone={userTimezone}>
      <InternationalContestPage event={internationalSite.event} />
    </InternationalContestTimezoneProvider>
  </InternationalLayout>
);

export const getServerSideProps = async (
  ctx: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<PageProps & GlobalServerValuesProp>> => {
  const client = createServerSideGraphQLClient(ctx);
  const data = await client.request<InternationalContestPageQuery>(
    gql`
      query InternationalContestPageQuery {
        user {
          ...GlobalServerCurrentUserFragment
        }
        international {
          international_site {
            id
            language
            site {
              id
              domain
              name
            }
            event {
              title
              prefix
              ...InternationalContestPageFragment
            }
          }
          profile {
            timezone {
              id
              name
            }
          }
        }
      }
      ${InternationalContestPage.fragments.event}
      ${getGlobalServerValues.fragments.currentUser}
    `,
  );

  const {
    user,
    international: { international_site, profile },
  } = data;

  if (!international_site || !international_site.event) return { notFound: true };

  return {
    props: {
      internationalSite: international_site,
      userTimezone: profile?.timezone ? profile.timezone.name : null,
      globalServerValues: getGlobalServerValues(ctx, user),
      ...(await serverSideTranslations(international_site.language, ["common", "internationalContest"])),
    },
  };
};

export default Page;
