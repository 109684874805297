import { BoxProps, Center, Divider, HStack, Stack, VStack } from "@chakra-ui/react";
import { mdiEmailOutline } from "@mdi/js";
import { Link as ChakraLink, Image as ChakraImage } from "@chakra-ui/next-js";
import { useContext } from "react";
import { gql } from "graphql-request";
import { useTranslation } from "next-i18next";
import { MdIcon } from "@/common/icons/MdIcon";
import { SocialNetworkIcon } from "@/common/components/SocialNetworkIcon";
import { InternationalSiteContext } from "@/modules/international/common/contexts/InternationalSiteContext";
import { BrandLogoMapped } from "@/modules/international/common/constants";
import { urls } from "@/reverse/urls";

export const InternationalFooter = ({ bgColor = "gray.800" }: { bgColor?: BoxProps["bgColor"] }) => {
  const { site, privacy_policy, terms_of_service, social_links } = useContext(InternationalSiteContext);
  const { t } = useTranslation("common");

  return (
    <VStack py={10} spacing={8} bgColor={bgColor}>
      <Center>
        <ChakraImage src={BrandLogoMapped[site.domain]} alt={site.name} width={250} height={100} objectFit="contain" />
      </Center>
      <Stack direction={["column", null, "row"]} align="center" w="md" spacing={[4, null, 2]}>
        {privacy_policy && (
          <>
            <ChakraLink
              flex={1}
              textAlign="center"
              fontSize="sm"
              color="white"
              href={urls["international:privacy_policy_page"]()}
            >
              {t("Privacy Policy")}
            </ChakraLink>
            <Divider orientation="vertical" bg="brand.300" h={[".5px", null, 6]} w={[20, null, ".5px"]} />
          </>
        )}
        {terms_of_service && (
          <>
            <ChakraLink
              flex={1}
              textAlign="center"
              fontSize="sm"
              color="white"
              href={urls["international:terms_page"]()}
            >
              {t("Terms of Service")}
            </ChakraLink>
            <Divider orientation="vertical" bg="brand.300" h={[".5px", null, 6]} w={[20, null, ".5px"]} />
          </>
        )}
        <ChakraLink flex={1} textAlign="center" fontSize="sm" color="white" href={urls["international:contact_page"]()}>
          {t("Contact Us")}
        </ChakraLink>
        <Divider orientation="vertical" bg="brand.300" h={[".5px", null, 6]} w={[20, null, ".5px"]} />
        <ChakraLink
          flex={1}
          textAlign="center"
          fontSize="sm"
          color="white"
          href="https://akwad.io/blog"
          target="_blank"
          rel="noreferrer"
        >
          {t("Blog")}
        </ChakraLink>
      </Stack>
      <HStack>
        <ChakraLink
          fontSize={["xs", null, null, "large"]}
          lineHeight={1}
          color="white"
          href={`mailto:contact@${site.domain}`}
        >
          {`contact@${site.domain}`}
        </ChakraLink>
        <MdIcon color="brand.200" icon={mdiEmailOutline} boxSize={6} />
      </HStack>
      <HStack spacing={8}>
        {!!social_links &&
          social_links.map(({ id, url, type }) => (
            <Center key={id}>
              <SocialNetworkIcon
                url={url}
                type={type}
                color="brand.200"
                _hover={{ color: "brand.300", borderColor: "brand.300" }}
                boxSize={[12, 14]}
                border="solid 1px"
                borderColor="brand.200"
                padding={3}
                rounded="full"
              />
            </Center>
          ))}
      </HStack>
    </VStack>
  );
};

InternationalFooter.fragments = {
  site: gql`
    fragment InternationalFooterFragment on InternationalSiteType {
      privacy_policy
      terms_of_service
      site {
        id
        domain
        name
      }
      social_links {
        id
        type
        url
      }
    }
  `,
};
