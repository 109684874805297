import { Intl as TemporalIntl, Temporal } from "temporal-polyfill";

export const toUserTimezone = (datetime: string) =>
  Temporal.Instant.from(datetime).toZonedDateTimeISO(TemporalIntl.DateTimeFormat().resolvedOptions().timeZone);

export const AKWAD_TITLE = "Akwad | اکواد";

export const toDollar = (number: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(number);
